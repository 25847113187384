import {
  EnergyDeviceFromApi,
  EnergyDevicePorts,
  EnergyPro,
  GenericErrors,
  IdListMapping,
  LoadingById,
  ResourceType,
  AcPowerSystem,
} from '@energybox/react-ui-library/dist/types';
import {
  mapArrayToObject,
  mapValues,
  values,
} from '@energybox/react-ui-library/dist/utils';

import * as R from 'ramda';
import { Actions } from '../actions/energy_pros';
import { ApiError, storeAPIerror } from '../utils/apiErrorFeedback';
import { formValidationErrors } from '../utils/formValidation';

export interface EditableFields {
  description: string;
  spaceId: number;
  title: string;
  uuid: string;
  identifier: string;
  sampleFrequency;
  acPowerSystem: AcPowerSystem['id'];
  poweredFromDistributionPanel: boolean;
  networkGroupId: string;
  energyProModel: 'ENERGY_PRO1' | 'ENERGY_PRO2';
}
export const editableFieldKeys = [
  'acPowerSystem',
  'description',
  'identifier',
  'sampleFrequency',
  'title',
  'uuid',
  'poweredFromDistributionPanel',
  'networkGroupId',
  'energyProModel',
];

const EnergyProModel = {
  ENERGYPRO: 'ENERGY_PRO1',
  ENERGYPRO2: 'ENERGY_PRO2',
};

export const editableFields = (energyPro: any) => ({
  ...R.pick(editableFieldKeys, energyPro),
  energyProModel: EnergyProModel[energyPro.model],
});

export type EditEnergyPro = {
  isLoading: boolean;
  isChanged: boolean;
  fields: EditableFields;
  formErrors: GenericErrors;
  formErrorsVisible: boolean;
  apiError: ApiError;
  showDeleteEnergyProModal: boolean;
  showRemoveAllEnergyDevicesFromBusModal: boolean;
  showUpdateEnergyProConfigModal: boolean;
};

export const newEnergyProFields = {
  description: '',
  sampleFrequency: 30,
  title: '',
  acPowerSystem: undefined,
  identifier: '',
  uuid: '',
  poweredFromDistributionPanel: true,
  networkGroupId: undefined,
  energyProModel: 'ENERGY_PRO1',
};

export type ActionsLoadingStatus = {
  [Actions.GET_ENERGY_PROS_BY_SITE_ID_LOADING]?: LoadingById;
  [Actions.GET_ENERGY_PROS_BY_DISTRIBUTION_PANEL_ID_LOADING]?: boolean;
};

export const newEnergyPro = {
  isLoading: false,
  isChanged: false,
  fields: newEnergyProFields,
  formErrors: formValidationErrors('energyPro', newEnergyProFields),
  formErrorsVisible: false,
  apiError: {},
};

export type EnergyProsById = {
  [id: string]: EnergyPro;
};

export type EnergyProIdsByDistributionPanelId = {
  [id: string]: string[];
};

export type AvailablePortsByEnergyProId = {
  [id: string]: {
    id: number;
    value: number;
  };
};

export type EnergyProLookup = {
  isLoading: boolean;
  energyPros: { uuid: string; signalStrength?: number | null }[];
};

export interface EnergyPros {
  energyProsById: EnergyProsById;
  energyProIdsBySiteId: IdListMapping;
  energyProIdsByDistributionPanelId: EnergyProIdsByDistributionPanelId;
  showNewEnergyProModal: boolean;
  showEditEnergyProModal: boolean;
  editById: EditById;
  primedForDelete: number;
  apiError: ApiError;
  availablePortsByEnergyProId: AvailablePortsByEnergyProId;
  loadingStatusByAction: ActionsLoadingStatus;
  lookupEnergyPro: EnergyProLookup;
}

export const initialState = {
  energyProsById: {},
  energyProIdsBySiteId: {},
  energyProIdsByDistributionPanelId: {},
  showNewEnergyProModal: false,
  showEditEnergyProModal: false,
  editById: {},
  primedForDelete: -1,
  apiError: {},
  availablePortsByEnergyProId: {},
  lookupEnergyPro: {
    isLoading: false,
    energyPros: [],
  },
  loadingStatusByAction: {},
};

export type EditById = {
  [id: string]: EditEnergyPro;
};

export const energyProFromApiResponse = (data: any): EnergyPro => ({
  resourceType: ResourceType.ENERGYPRO,
  distributionPanelId: data.distributionPanelId,
  distributionPanel: data.distributionPanel,
  acPowerSystem: data.acPowerSystem,
  description: data.description,
  createdAt: data.createdAt,
  bus1Device: data.bus1Device,
  bus2Device: data.bus2Device,
  title: data.title,
  id: data.id,
  identifier: data.identifier,
  isCTConfigured: data.isCTConfigured,
  legacyId: data.legacyId,
  macAddress: data.macAddress,
  sampleFrequency: data.sampleFrequency,
  sensors: data.sensors,
  uuid: data.uuid,
  vendor: data.vendor,
  model: data.model,
  poweredFromDistributionPanel: data.poweredFromDistributionPanel,
  firmwareVersion: R.pathOr(null, ['gatewayInfo', 'firmwareVersion'], data),
  networkGroupId: data.networkGroupId,
  warnings: data.warnings,
  configUpdateByUserName: data.configUpdateByUserName,
  lastUpdateConfigTime: data.lastUpdateConfigTime,
});

const editEnergyProObject = (apiResponse: EnergyPro) => {
  const energyPro = energyProFromApiResponse(apiResponse);
  return {
    id: apiResponse.id,
    isLoading: false,
    formErrorsVisible: false,
    showDeleteEnergyProModal: false,
    fields: editableFields(energyPro),
    apiError: {},
    showUpdateEnergyProConfigModal: false,
  };
};

const mapGetEnergyProsToStore = (
  apiResponse: EnergyPro[],
  state: EnergyPros
) => {
  const energyProsById = mapArrayToObject(apiResponse);

  return [
    R.assoc(
      'energyProsById',
      R.mergeRight(
        R.view(R.lensProp('energyProsById'), state),
        mapArrayToObject(mapValues(energyProsById, energyProFromApiResponse))
      )
    ),
    R.assoc(
      'editById',
      R.mergeRight(
        mapArrayToObject(mapValues(energyProsById, editEnergyProObject)),
        R.view(R.lensProp('editById'), state)
      )
    ),
    R.assoc(
      'availablePortsByEnergyProId',
      mapArrayToObject(
        mapValues(energyProsById, calculateEnergyProAvailablePorts)
      )
    ),
  ];
};

const mapGetEnergyProToStore = (apiResponse: EnergyPro) => {
  const energyPro: EnergyPro = energyProFromApiResponse(apiResponse);

  return [
    R.assocPath(
      ['editById', String(energyPro.id)],
      editEnergyProObject(apiResponse)
    ),
    R.assocPath(['energyProsById', String(energyPro.id)], energyPro),
    R.assocPath(
      ['availablePortsByEnergyProId', String(energyPro.id)],
      calculateEnergyProAvailablePorts(energyPro)
    ),
  ];
};

const calculateEnergyProAvailablePorts = (energyPro: EnergyPro) => {
  let availablePorts = 48;

  const subtractPortsByBusDevice = (busDevice: EnergyDeviceFromApi | null) => {
    if (!!busDevice) {
      availablePorts -= EnergyDevicePorts[busDevice._entity];
      subtractPortsByBusDevice(busDevice.busDevice);
    } else return;
  };

  subtractPortsByBusDevice(energyPro.bus1Device);
  subtractPortsByBusDevice(energyPro.bus2Device);

  return {
    id: energyPro.id,
    value: availablePorts,
  };
};

const mapEnergyProIdsByDistributionPanelId = (apiResponse: EnergyPro[]) => {
  const eProIdsByPanelId = {};

  apiResponse.forEach(ePro => {
    if (eProIdsByPanelId[ePro.distributionPanelId]) {
      const newArray = eProIdsByPanelId[ePro.distributionPanelId];
      newArray.push(String(ePro.id));
      eProIdsByPanelId[ePro.distributionPanelId] = newArray;
    } else {
      eProIdsByPanelId[ePro.distributionPanelId] = [String(ePro.id)];
    }
  });

  return eProIdsByPanelId;
};

export const sortedEnergyPro = ({ energyProsById }: EnergyProsById) =>
  values(energyProsById).sort((a, b) => a.title.localeCompare(b.title));

const energyPros = (state: EnergyPros = initialState, action: any) => {
  switch (action.type) {
    case Actions.GET_ENERGY_PROS_BY_SITE_ID_SUCCESS:
      const apiRes = action.data;
      const energyProIds = apiRes.map(({ id }) => id);
      return R.pipe(
        R.assocPath(['energyProIdsBySiteId', [action.siteId]], energyProIds),
        R.assoc(
          'energyProIdsByDistributionPanelId',
          R.mergeRight(
            R.view(R.lensProp('energyProIdsByDistributionPanelId'), state),
            mapEnergyProIdsByDistributionPanelId(action.data)
          )
        ),
        R.assocPath(
          [
            'loadingStatusByAction',
            Actions.GET_ENERGY_PROS_BY_SITE_ID_LOADING,
            [action.siteId],
          ],
          false
        ),
        ...mapGetEnergyProsToStore(action.data, state)
      )(state);

    case Actions.GET_ENERGY_PROS_BY_SITE_ID_LOADING:
      return R.assocPath(
        [
          'loadingStatusByAction',
          Actions.GET_ENERGY_PROS_BY_SITE_ID_LOADING,
          [action.siteId],
        ],
        true,
        state
      );

    case Actions.REMOVE_ENERGY_PRO_SENSOR_FROM_PANEL_MAIN_BREAKER_SUCCESS:
    case Actions.ADD_ENERGY_PRO_SENSOR_TO_PANEL_MAIN_BREAKER_SUCCESS:
    case Actions.APPEND_ENERGY_DEVICE_SUCCESS:
    case Actions.GET_ENERGY_PRO_BY_UUID_SUCCESS:
    case Actions.GET_ENERGY_PRO_SUCCESS:
      return R.pipe(...mapGetEnergyProToStore(action.data))(state);

    case Actions.GET_ENERGY_PROS_BY_DISTRIBUTION_PANEL_ID_SUCCESS:
      return R.pipe(
        R.assocPath(
          ['energyProIdsByDistributionPanelId', String(action.id)],
          action.data.map(({ id }) => id.toString())
        ),
        ...mapGetEnergyProsToStore(action.data, state),
        R.assocPath(
          [
            'loadingStatusByAction',
            Actions.GET_ENERGY_PROS_BY_DISTRIBUTION_PANEL_ID_LOADING,
          ],
          false
        )
      )(state);

    case Actions.GET_ENERGY_PROS_BY_DISTRIBUTION_PANEL_ID_LOADING:
      return R.assocPath(
        [
          'loadingStatusByAction',
          Actions.GET_ENERGY_PROS_BY_DISTRIBUTION_PANEL_ID_LOADING,
        ],
        true,
        state
      );

    case Actions.GET_ENERGY_PROS_BY_DISTRIBUTION_PANEL_ID_ERROR:
      return R.assocPath(
        [
          'loadingStatusByAction',
          Actions.GET_ENERGY_PROS_BY_DISTRIBUTION_PANEL_ID_LOADING,
        ],
        false,
        state
      );

    case Actions.TOGGLE_NEW_ENERGY_PRO_MODAL:
      return R.pipe(
        R.assocPath(['editById', 'new'], newEnergyPro),
        R.assoc('showNewEnergyProModal', action.value)
      )(state);

    case Actions.TOGGLE_EDIT_ENERGY_PRO_MODAL:
      return R.pipe(R.assoc('showEditEnergyProModal', action.value))(state);

    case Actions.CREATE_NEW_ENERGY_PRO:
      return R.assocPath(['editById', 'new'], newEnergyPro, state);

    case Actions.DISPLAY_FORM_ERRORS:
      return R.assocPath(
        ['editById', String(action.id), 'formErrorsVisible'],
        action.value,
        state
      );

    case Actions.UPDATE_FIELD:
      let updatedField = R.assoc(
        action.field,
        action.value,
        R.path(['editById', String(action.id), 'fields'], state)
      );
      return R.pipe(
        R.assocPath(['editById', String(action.id), 'fields'], updatedField),
        R.assocPath(['editById', String(action.id), 'isChanged'], true),
        R.assocPath(
          ['editById', String(action.id), 'formErrors'],
          formValidationErrors('energyPro', updatedField)
        )
      )(state);

    case Actions.CREATE_ENERGY_PRO_LOADING:
      return R.assocPath(['editById', 'new', 'isLoading'], true, state);

    case Actions.CREATE_ENERGY_PRO_SUCCESS: {
      const id = String(action.data.id);
      const distributionPanelId = String(action.data.distributionPanelId);
      const newList = (
        state.energyProIdsByDistributionPanelId[distributionPanelId] || []
      ).concat(id);
      return R.pipe(
        ...mapGetEnergyProToStore(action.data),
        R.assocPath(
          ['energyProIdsByDistributionPanelId', distributionPanelId],
          newList
        ),
        R.assocPath(['editById', 'new', 'isLoading'], false),
        R.assoc('showNewEnergyProModal', false)
      )(state);
    }

    case Actions.CREATE_ENERGY_PRO_ERROR:
      return R.pipe(
        R.assocPath(['editById', 'new', 'apiError'], storeAPIerror(action)),
        R.assocPath(['editById', 'new', 'isLoading'], false)
      )(state);

    case Actions.PATCH_ENERGY_PRO_LOADING:
      return R.assocPath(
        ['editById', String(action.id), 'isLoading'],
        true,
        state
      );

    case Actions.PATCH_ENERGY_PRO_SUCCESS:
      const patchedEnergyPro = energyProFromApiResponse(action.data);
      return R.pipe(
        R.assocPath(['energyProsById', String(action.id)], patchedEnergyPro),
        R.assoc('showEditEnergyProModal', false),
        R.assocPath(['editById', String(action.id), 'isChanged'], false),
        R.assocPath(['editById', String(action.id), 'isLoading'], false)
      )(state);

    case Actions.PATCH_ENERGY_PRO_ERROR:
      return R.pipe(
        R.assocPath(
          ['editById', String(action.id), 'apiError'],
          storeAPIerror(action)
        ),
        R.assocPath(['editById', String(action.id), 'isLoading'], false)
      )(state);

    case Actions.RESET_EDIT_ENERGY_PRO: {
      var fields = editableFields(
        R.path(['energyProsById', String(action.id)], state)
      );

      return R.pipe(
        R.assocPath(['editById', String(action.id), 'isChanged'], false),
        R.assocPath(['editById', String(action.id), 'fields'], fields),
        R.assocPath(['editById', action.id, 'formErrorsVisible'], false),
        R.assocPath(
          ['editById', String(action.id), 'formErrors'],
          formValidationErrors('energyPro', fields)
        )
      )(state);
    }

    case Actions.REMOVE_DEVICE_FROM_ENERGY_PRO_BUS_LOADING:
    case Actions.DELETE_ENERGY_PRO_LOADING:
      return R.assocPath(
        ['editById', String(action.id), 'isLoading'],
        true,
        state
      );

    case Actions.DELETE_ENERGY_PRO_SUCCESS: {
      const id = String(action.id);
      const ePro = state.energyProsById[id];
      const distributionPanelId = String(ePro.distributionPanelId);
      const newListOfEproIds = (
        state.energyProIdsByDistributionPanelId[distributionPanelId] || []
      ).filter(eProId => eProId !== id);
      if (action.data) {
        return R.pipe(
          R.dissocPath(['energyProsById', id]),
          R.dissocPath(['editById', id]),
          R.assocPath(
            ['energyProIdsByDistributionPanelId', distributionPanelId],
            newListOfEproIds
          )
        )(state);
      }
      return state;
    }

    case Actions.DELETE_ENERGY_PRO_ERROR:
      const id = String(action.id);
      return R.pipe(
        R.assocPath(['editById', id, 'apiError'], storeAPIerror(action)),
        R.assocPath(['editById', id, 'isLoading'], false)
      )(state);

    case Actions.REMOVE_DEVICE_FROM_ENERGY_PRO_BUS_SUCCESS:
      return R.pipe(
        R.assocPath(['editById', action.id, 'apiError'], {}),
        R.assocPath(['editById', action.id, 'isLoading'], false)
      )(state);

    case Actions.TOGGLE_DELETE_ENERGY_PRO_MODAL: {
      return R.pipe(
        R.assocPath(
          ['editById', action.id, 'showDeleteEnergyProModal'],
          action.value
        ),
        R.assoc('apiError', {})
      )(state);
    }

    case Actions.TOGGLE_REMOVE_ALL_ENERGY_DEVICES_FROM_BUS_MODAL: {
      return R.pipe(
        R.assocPath(
          ['editById', action.id, 'showRemoveAllEnergyDevicesFromBusModal'],
          action.value
        ),
        R.assoc('apiError', {})
      )(state);
    }

    case Actions.TOGGLE_UPDATE_ENERGY_PRO_CONFIGURATION_MODAL: {
      return R.pipe(
        R.assocPath(
          ['editById', action.id, 'showUpdateEnergyProConfigModal'],
          action.value
        ),
        R.assocPath(['editById', action.id, 'apiError'], {})
      )(state);
    }

    case Actions.UPDATE_ENERGY_PRO_CONFIGURATION_ERROR: {
      return R.pipe(
        R.assocPath(['editById', action.id, 'apiError'], storeAPIerror(action))
      )(state);
    }

    case Actions.LOOKUP_ENERGY_PRO_SUCCESS: {
      return R.pipe(
        R.assocPath(['lookupEnergyPro', 'energyPros'], action.data || []),
        R.assocPath(['lookupEnergyPro', 'isLoading'], false)
      )(state);
    }
    case Actions.LOOKUP_ENERGY_PRO_LOADING: {
      return R.pipe(
        R.assocPath(['lookupEnergyPro', 'energyPros'], []),
        R.assocPath(['lookupEnergyPro', 'isLoading'], true)
      )(state);
    }
    case Actions.LOOKUP_ENERGY_PRO_ERROR: {
      return R.pipe(
        R.assocPath(['lookupEnergyPro', 'energyPros'], []),
        R.assocPath(['lookupEnergyPro', 'isLoading'], false)
      )(state);
    }

    case Actions.PRIME_FOR_DELETE:
      return R.assoc('primedForDelete', action.id, state);

    case Actions.UNSTAGE_DELETE:
      return R.assoc('primedForDelete', -1, state);

    default:
      return state;
  }
};

export default energyPros;
