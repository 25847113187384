import {
  DistributionPanel,
  EnergyPro,
  MainBreaker,
  SiteEnergyCalculationMode,
} from '@energybox/react-ui-library/dist/types';
import {
  ActionPayLoad,
  DevicePortDisplayData,
  DPTableState,
  getActivePower,
  getCurrent,
  getPhase,
  getPowerFactor,
  getVoltage,
  UpdateEnergyDeviceAction,
  UpdateEntity,
  useEnergyProStreamDataBySensorId,
} from './../DPSetUpTable';
import { EntityInfoToSub } from '@energybox/react-ui-library/dist/types/StreamApi';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../../reducers';
import React, { useEffect } from 'react';
import {
  classNames,
  global,
  KW_UNIT,
} from '@energybox/react-ui-library/dist/utils';
import styles from './../DPSetUpTable.module.css';
import DeviceOnlineState, {
  DisplayType,
} from '../../../DeviceStatus/DeviceOnlineState';
import { Checkbox, Tooltip } from '@energybox/react-ui-library/dist/components';
import NewEnergyDeviceSensorModal from '../../../EnergyDevices/NewEnergyDeviceSensorModal';
import {
  convertCtToTitle,
  EnergyProWarning,
  getPhaseDisplayText,
  isSiteTotalChecked,
  mapWarningToText,
} from '../../../../utils/energyPro';
import DeviceConnectionStatus from '../../../../components/DeviceConnectionStatus';
import {
  subscribeToProposedPhaseReadings,
  unsubscribeToProposedPhaseReadings,
} from '../../../../actions/streamApi';
import { useGetSite } from '../../../../hooks/useSites';
import { showEditEnergyProModal } from '../../../../actions/energy_pros';
import EditEnergyProModal from '../../../EnergyPros/EditEnergyProModal/EditEnergyProModal';
import { StagingSensorConfig } from '../../../../reducers/energy_devices';
import { addStagingSensorConfig } from '../../../../actions/energy_devices';
import { showEditCircuitBreakerModal } from '../../../../actions/circuit_breakers';
import EditCircuitBreakerModal from '../../../CircuitBreakers/EditCircuitBreakerModal/EditCircuitBreakerModal';
import { setEditMainBreaker } from '../../../../actions/main_breakers';
import { WarningIcon } from '@energybox/react-ui-library/dist/icons';

type EnergyProConfigProps = {
  distributionPanel: DistributionPanel;
  siteId: number;
  configByDeviceId: DPTableState;
  energyPro: EnergyPro;
  energyProSubscriptionInfo: EntityInfoToSub[] | undefined;
  setUnconfirmedUpdateAction: (updateAction: ActionPayLoad | undefined) => void;
  setNewSensorDevice: (epro: EnergyPro) => void;
  isEditing?: boolean;
};

const EnergyProConfig = ({
  distributionPanel,
  siteId,
  configByDeviceId,
  energyPro,
  energyProSubscriptionInfo,
  setUnconfirmedUpdateAction,
  isEditing,
}: EnergyProConfigProps) => {
  const dispatch = useDispatch();
  const portsData = Object.values(configByDeviceId[energyPro.id] || {});

  // for portsData, first sort by port number, then fill up to the number of poles
  // say it's 1 and 3, and poles are 3, then fill 2
  const poles = distributionPanel.mainBreaker.poles;
  portsData.sort((a, b) => a.port - b.port);
  let processedPortsData: (DevicePortDisplayData | undefined)[] = [];
  for (let i = 0; i < poles; i++) {
    const sensor = portsData.find(data => data?.port === i + 1);
    processedPortsData.push(sensor);
  }

  const { energyCalculationMode } = useGetSite(siteId) || {};
  const energySensorsReadingById = useEnergyProStreamDataBySensorId(
    energyPro.id
  );

  const showingNewEnergyDeviceSensorModal = useSelector(
    ({ energyDevices }: ApplicationState) => {
      return energyDevices.showNewEnergyDeviceSensorModal;
    }
  );

  const addSensorConfig = (key: string, sensorConfig: StagingSensorConfig) => {
    dispatch(addStagingSensorConfig(key, sensorConfig));
  };

  const { id, title, uuid, firmwareVersion } = energyPro;

  useEffect(() => {
    dispatch(
      subscribeToProposedPhaseReadings(
        energyPro.vendor,
        energyPro.uuid,
        energyPro.id
      )
    );

    return () => {
      dispatch(
        unsubscribeToProposedPhaseReadings(
          energyPro.vendor,
          energyPro.uuid,
          energyPro.id
        )
      );
    };
  }, [energyPro]);

  useEffect(() => {
    dispatch(setEditMainBreaker(distributionPanel.mainBreaker));
  }, [distributionPanel]);

  return (
    <>
      <div
        className={classNames(styles.busDeviceTableGen2, styles.topSeparator)}
      >
        <div className={styles.tableHeader}>Device Name</div>
        <div />
        <div
          className={classNames(
            styles.tableHeader,
            styles.dropdownHeaderPadding
          )}
        >
          Index
        </div>
        <div className={styles.tableHeader}>Site Total</div>
        <div
          className={classNames(
            styles.tableHeader,
            styles.dropdownHeaderPadding
          )}
        >
          Breakers Name
        </div>
        <div
          className={classNames(
            styles.tableHeader,
            styles.dropdownHeaderPadding
          )}
        >
          CT Type
        </div>
        <div className={classNames(styles.tableHeader)}>Phase</div>
        <div
          className={classNames(
            styles.tableHeader,
            styles.dropdownHeaderPadding
          )}
        >
          CT Polarity
        </div>
        <div className={styles.tableHeader}>
          <div className={styles.sensorReading}>Current (A)</div>
        </div>
        <div className={styles.tableHeader}>
          <div className={styles.sensorReading}>Voltage (V)</div>
        </div>
        <div className={styles.tableHeader}>
          <div className={styles.sensorReading}>Power Factor</div>
        </div>
        <div className={styles.tableHeader}>
          <div className={styles.sensorReading}>Active Power ({KW_UNIT})</div>
        </div>
      </div>
      <div className={classNames(styles.busDeviceTableGen2)}>
        <div style={{ gridRow: 'span 3', backgroundColor: 'white' }}>
          <div className={styles.eproDeviceName}>
            {energyProSubscriptionInfo && (
              <DeviceOnlineState
                displayType={DisplayType.STATUS_ONLY_WITHOUT_TEXT}
                devices={energyProSubscriptionInfo}
              />
            )}
            <div style={{ position: 'relative' }}>
              <span
                className={styles.eproDeviceNameTitle}
                onClick={() => {
                  dispatch(showEditEnergyProModal(String(id)));
                }}
                style={{ cursor: 'pointer' }}
              >
                {title}
              </span>

              <EditEnergyProModal
                id={energyPro.id}
                energyPro={energyPro}
                siteId={siteId}
                distributionPanelEnergySource={distributionPanel.energySource}
              />

              <br />
              <span className={styles.eproDeviceNameSubTitle}>
                UUID: {uuid ? uuid : global.NOT_AVAILABLE}
                <br />
                FW: {firmwareVersion ? firmwareVersion : global.NOT_AVAILABLE}
              </span>
            </div>
          </div>
        </div>

        {processedPortsData.map((data, row, rows) => {
          if (data === undefined) {
            return (
              <React.Fragment key={`blankPort${row}`}>
                <div />
                <div className={row % 2 === 0 ? styles.white : styles.gray}>
                  <div
                    className={classNames(
                      styles.centered,
                      styles.dropdownHeaderPadding
                    )}
                  >
                    <DeviceConnectionStatus
                      connectionStatus="INACTIVE"
                      hideText
                    />
                    Port {row + 1}
                  </div>
                </div>
                {new Array(9).fill(0).map((_, index) => (
                  <div
                    className={classNames(
                      row % 2 === 0 ? styles.white : styles.gray,
                      index > 0 && index < 5 && styles.dropdownHeaderPadding
                    )}
                    key={`blankPort${index}`}
                  >
                    {global.NOT_AVAILABLE}
                  </div>
                ))}
              </React.Fragment>
            );
          }

          const {
            port,
            energyDeviceId,
            energySensorId,
            ct,
            reversePolarity,
            phase,
            disabled,
            warnings,
          } = data;

          const breaker: MainBreaker | undefined =
            distributionPanel.mainBreaker;

          const backgroundColor = row % 2 === 0 ? 'white' : 'gray';
          const siteTotalChecked =
            energyCalculationMode &&
            isSiteTotalChecked(
              distributionPanel.mdp,
              true,
              energyCalculationMode,
              breaker.siteTotal
            );
          const isCheckboxDisabled =
            breaker === undefined ||
            energyCalculationMode !==
              SiteEnergyCalculationMode.FLAGGED_BREAKERS;

          const detectedPhase = getPhase(
            energySensorId,
            energySensorsReadingById
          );

          const { phaseToShow } = getPhaseDisplayText(phase, detectedPhase);

          return (
            <React.Fragment key={`CT_Port${port}`}>
              <div className={styles[backgroundColor]} />
              <div
                className={classNames(
                  styles[backgroundColor],
                  styles.dropdownHeaderPadding
                )}
              >
                <DeviceConnectionStatus
                  connectionStatus={disabled || !ct ? 'INACTIVE' : true}
                  hideText
                />
                Port {port}
              </div>
              <div className={styles[backgroundColor]}>
                <Checkbox
                  checked={siteTotalChecked}
                  disabled={isCheckboxDisabled}
                  onChange={() => {
                    if (breaker)
                      setUnconfirmedUpdateAction({
                        type: UpdateEnergyDeviceAction.SITE_TOTAL,
                        deviceTitle: energyPro.title,
                        id: energyDeviceId,
                        breakerId: +breaker.id,
                        port,
                        value: !breaker.siteTotal,
                        entity: UpdateEntity.MAIN_BREAKER,
                      });
                  }}
                />
              </div>
              <div className={styles[backgroundColor]}>
                <div
                  className={styles.tableCellPadding}
                  style={{ position: 'relative' }}
                >
                  <span
                    className={classNames(
                      styles.eproDeviceNameTitle,
                      styles.breakerClickable
                    )}
                    onClick={() => {
                      dispatch(
                        showEditCircuitBreakerModal(data.breaker.id, row)
                      );
                    }}
                  >
                    {data.breaker.title}
                  </span>
                  <EditCircuitBreakerModal
                    breakerId={data.breaker.id}
                    panelId={distributionPanel.id}
                    energyPro={energyPro}
                    mainBreakerPos={row}
                    poles={distributionPanel.mainBreaker.poles}
                    siteTotalChecked={siteTotalChecked}
                    isSiteTotalCheckboxDisabled={isCheckboxDisabled}
                    isMainBreaker
                  />
                </div>
              </div>
              <div className={styles[backgroundColor]}>
                <div className={styles.tableCellPadding}>
                  {convertCtToTitle(ct)}
                </div>
              </div>
              <div className={styles[backgroundColor]}>{phaseToShow}</div>
              <div className={styles[backgroundColor]}>
                <div className={styles.tableCellPadding}>
                  {warnings?.some(
                    w => w === EnergyProWarning.MAINS_REVERSE_POLARITY
                  ) ? (
                    <ReversePolarityWarning />
                  ) : reversePolarity ? (
                    'Reverse'
                  ) : (
                    'Normal'
                  )}
                </div>
              </div>

              <div className={styles[backgroundColor]}>
                <div className={styles.sensorReading}>
                  {getCurrent(energySensorId, energySensorsReadingById)}
                </div>
              </div>
              <div className={styles[backgroundColor]}>
                <div className={styles.sensorReading}>
                  {getVoltage(energySensorId, energySensorsReadingById)}
                </div>
              </div>
              <div className={styles[backgroundColor]}>
                <div className={styles.sensorReading}>
                  {getPowerFactor(energySensorId, energySensorsReadingById)}
                </div>
              </div>
              <div className={styles[backgroundColor]}>
                <div className={styles.sensorReading}>
                  {getActivePower(energySensorId, energySensorsReadingById)}
                </div>
              </div>
            </React.Fragment>
          );
        })}
      </div>
      {showingNewEnergyDeviceSensorModal && energyPro && (
        <NewEnergyDeviceSensorModal
          panelId={distributionPanel.id}
          energyDeviceId={energyPro.id}
          sensors={energyPro.sensors}
          energyProId={energyPro.id}
          breakerId={distributionPanel.mainBreaker.id}
          refetchEnergyPro={true}
        />
      )}
    </>
  );
};

const ReversePolarityWarning = () => (
  <div className={styles.warningTooltip}>
    <Tooltip
      content={
        <>
          <strong>Warning: CT Reverse</strong>
          <div>{mapWarningToText[EnergyProWarning.MAINS_REVERSE_POLARITY]}</div>
        </>
      }
      arrowDirection="top"
      underline={false}
    >
      <div style={{ marginTop: 2 }}>
        <WarningIcon size={14} />
      </div>
    </Tooltip>
  </div>
);

export default EnergyProConfig;
