import React from 'react';
import { connect } from 'react-redux';
import {
  SensorType,
  CurrentUser,
  Locale,
  DeviceState,
} from '@energybox/react-ui-library/dist/types';
import {
  createTemperatureString,
  global,
  isDefined,
} from '@energybox/react-ui-library/dist/utils';
import { formatEpochDate } from '@energybox/react-ui-library/dist/utils';
import styles from '../ValueChip/ValueChip.module.css';
import { ApplicationState } from '../../reducers';

interface OwnProps {
  uuid: string;
  deviceInfo: any;
  secondaryText?: string;
  types: SensorType[];
}

interface Props extends OwnProps {
  locale: Locale;
  user?: CurrentUser;
}

class UninstalledValueChip extends React.Component<Props> {
  renderSampleValue = (): { date: string; value: string } => {
    const { deviceInfo, user, locale } = this.props;
    const { fullDateTimeFormat } = locale;
    const dotStatus =
      deviceInfo.device_specific_status['com.energybox.DotStatus'];
    const readingTypes = dotStatus.reading_types;
    const reading = dotStatus.reading;

    let returnObject = {
      date: '',
      value: global.NOT_AVAILABLE as string,
    };

    if (
      reading &&
      (readingTypes.includes('TEMPERATURE') || readingTypes.includes('AMBIENT'))
    ) {
      const temperatureReading =
        reading['com.energybox.TemperatureSensorReading'] ||
        reading['com.energybox.AmbientHumidityReading'];
      if (temperatureReading) {
        returnObject.date = formatEpochDate(
          temperatureReading.timestamp,
          fullDateTimeFormat
        );
        returnObject.value = createTemperatureString(
          temperatureReading.temperature,
          user!
        );
      }
    }

    if (reading && readingTypes.includes('HUMIDITY')) {
      const humidityReading = reading['com.energybox.AmbientHumidityReading'];
      if (humidityReading) {
        const humidity = (humidityReading.humidity / 10).toFixed(1);
        returnObject.value = `${returnObject.value} @ ${humidity}%`;
        returnObject.date = formatEpochDate(
          humidityReading.timestamp,
          fullDateTimeFormat
        );
      }
    }

    if (reading && readingTypes.includes('BINARY')) {
      const binaryReading = reading['com.energybox.BinaryAccessReading'];
      if (binaryReading) {
        returnObject.value = binaryReading.access_state ? 'Open' : 'Closed';
        returnObject.date = formatEpochDate(
          binaryReading.timestamp,
          fullDateTimeFormat
        );
      }
    }

    return returnObject;
  };

  render() {
    const { uuid, secondaryText } = this.props;
    const valueObject = this.renderSampleValue();

    return (
      <span key={uuid} className={styles.sensorIconAndTextGrouping}>
        <span className={styles.sensorTypeText}>
          <span title={valueObject.date ? valueObject.date : ''}>
            {valueObject.value}
          </span>
          {secondaryText && (
            <div className={styles.secondaryText}> {secondaryText}</div>
          )}
        </span>
      </span>
    );
  }
}

const mapStateToProps = ({ app }: ApplicationState, { uuid }: OwnProps) => ({
  locale: app.locale,
  user: app.currentUser,
});

export default connect(mapStateToProps)(UninstalledValueChip);
