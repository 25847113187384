import { Button } from '@energybox/react-ui-library/dist/components';
import {
  HidePwdIcon,
  ShowPwdIcon,
} from '@energybox/react-ui-library/dist/icons';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  getActuatorsBySiteId,
  getTestResults,
  showNewActuatorModal,
} from '../../../actions/control_boards';
import { FixedContentWrapper } from '../../../components/Page';
import ShowDetailPageHeader from '../../../components/ShowDetailPageHeader';
import { useActuatorsBySiteId } from '../../../hooks/useControlBoard';
import { useGetSite } from '../../../hooks/useSites';
import { ApplicationState } from '../../../reducers';
import { RunTestButtonText } from '../../../types/global';
import DeleteActuatorModal from '../../Actuators/DeleteActuatorModal';
import EditActuatorModal from '../../Actuators/EditActuatorModal';
import NewActuatorModal from '../../Actuators/NewActuatorModal';
import UnmappedRelaysConfiguration from './UnmappedRelaysConfiguration';
import styles from './RunConfigurationTest.module.css';
import { StartSequenceForRunTest } from './StartSequenceForRunTest';
import EquipmentsConfiguration from './EquipmentsConfiguration';
import {
  disableRunTestButton,
  enableStartSequence,
} from '../../../actions/run_configuration';

export const RunConfigurationTest: React.FC = () => {
  const history = useHistory();
  const pathname = history.location.pathname.split('/');
  const siteId = pathname[2];
  const dispatch = useDispatch();
  const [actuatorIdToEdit, setActuatorIdToEdit] = useState(-1);
  const [showUnmappedRelays, setShowUnmappedRelays] = useState(false);
  const [compareId, setCompareId] = useState(0);

  const showNewActuator = id => {
    dispatch(showNewActuatorModal(Number(id)));
    setCompareId(id);
  };
  const showingNewActuatorModal = useSelector(
    ({ controlBoards }: ApplicationState) => {
      return controlBoards.showNewActuatorModal;
    }
  );
  const showingEditActuatorModal = useSelector(
    ({ controlBoards }: ApplicationState) => {
      return controlBoards.showEditActuatorModal;
    }
  );
  const showingDeleteActuatorModal = useSelector(
    ({ controlBoards }: ApplicationState) => {
      return controlBoards.showDeleteActuatorModal;
    }
  );
  const startSequence = useSelector(
    ({ runConfiguration }: ApplicationState) => {
      return runConfiguration.disableStartSequence;
    }
  );
  const testResults = useSelector(({ controlBoards }: ApplicationState) => {
    return controlBoards.testResults;
  });
  const equipmentIdArray: number[] = [];
  Object.entries(testResults.results).forEach(([id, results]) => {
    if (results !== '{}') return equipmentIdArray.push(+id);
  });
  const site = useGetSite(siteId);
  const actuators = useActuatorsBySiteId(siteId);
  actuators?.sort((a, b) => a.port - b.port);
  const actuator = actuators?.find(actuator => {
    if (compareId === actuator.equipmentId) {
      return actuator;
    }
  });
  const controlBoardIdViaActuator = actuator?.controlBoardId!;
  const equipmentIds = actuators?.map(id => {
    return id.equipmentId;
  });
  const filteredEquipmentIds = [...new Set(equipmentIds)];
  const equipments = actuators?.map(equipment => {
    return equipment.equipment;
  });
  const controlBoardIds = actuators?.map(id => {
    return id.controlBoardId;
  });
  const filteredControlBoardIds = [...new Set(controlBoardIds)];

  const actuatorViaControlBoard = actuators?.find(actuator => {
    return actuatorIdToEdit === actuator.id ? actuator : '';
  });

  const equipmentIdViaActurator = actuatorViaControlBoard?.equipmentId;

  useEffect(() => {
    dispatch(getTestResults(siteId));
  }, []);

  useEffect(() => {
    if (Object.values(testResults.results).length > 0) {
      dispatch(enableStartSequence());
      dispatch(disableRunTestButton());
    }
  }, [testResults]);
  useEffect(() => {
    dispatch(getActuatorsBySiteId(siteId));
  }, [actuatorIdToEdit]);

  return (
    <>
      <ShowDetailPageHeader
        name={site ? site.title : ''}
        description={site?.address}
        resourceName="Panel"
        hideDelete
        siteId={Number(siteId)}
      />
      <div className={styles.root}>
        <FixedContentWrapper>
          <StartSequenceForRunTest
            toggleStartSequence={startSequence}
            siteId={siteId}
            actuators={actuators?.length ? actuators : []}
            equipments={equipments?.length ? equipments : []}
            filteredControlBoardIds={filteredControlBoardIds}
          />
          <div>
            <span
              className={styles.equipmentCount}
            >{`${filteredEquipmentIds.length} Equipments`}</span>
            <Button
              variant="text"
              className={`${styles.relayButton} ${
                showUnmappedRelays ? styles.hideUnmappedRelays : ''
              }`}
              onClick={() => setShowUnmappedRelays(!showUnmappedRelays)}
            >
              {showUnmappedRelays ? (
                <>
                  <ShowPwdIcon size={16} />
                  <span className={styles.unmappedRelayText}>
                    {RunTestButtonText.HIDE_UNMAPPED_RELAYS}
                  </span>
                </>
              ) : (
                <>
                  <HidePwdIcon size={16} />
                  <span className={styles.unmappedRelayText}>
                    {RunTestButtonText.SHOW_UNMAPPED_RELAYS}
                  </span>
                </>
              )}
            </Button>
          </div>
          {showUnmappedRelays &&
            filteredControlBoardIds.map((controlBoardId: number) => {
              return (
                <>
                  <UnmappedRelaysConfiguration
                    controlBoardId={controlBoardId}
                    siteId={siteId}
                    equipmentIdsArray={equipmentIdArray}
                  />
                </>
              );
            })}

          {filteredEquipmentIds?.map(id => {
            return (
              <>
                <EquipmentsConfiguration
                  equipmentId={id}
                  siteId={siteId}
                  showNewActuator={showNewActuator}
                  setActuatorIdToEdit={setActuatorIdToEdit}
                  equipmentIdsArray={equipmentIdArray}
                />
              </>
            );
          })}
        </FixedContentWrapper>
      </div>
      {showingNewActuatorModal && (
        <NewActuatorModal
          equipmentId={compareId}
          preselectedControlBoardId={controlBoardIdViaActuator}
        />
      )}
      {showingEditActuatorModal && (
        <EditActuatorModal
          equipmentId={equipmentIdViaActurator!}
          actuatorId={actuatorIdToEdit}
          preselectedControlBoardId={controlBoardIdViaActuator}
        />
      )}
      {showingDeleteActuatorModal && (
        <DeleteActuatorModal
          equipmentId={equipmentIdViaActurator!}
          actuatorId={actuatorIdToEdit}
        />
      )}
    </>
  );
};

export default RunConfigurationTest;
