import { Equipment } from '@energybox/react-ui-library/dist/types';
import { Actions } from '../actions/aggregate_views';
import * as R from 'ramda';

export type CbToEquipmentIds = {
  controlBoardId: number;
  equipmentIds: number[];
};
export type NonHvacEquipmentsById = {
  siteId: string;
  equipment: Equipment[];
  networkGroupIds: number[];
  cbToEquipmentIds: CbToEquipmentIds[];
};

export type AggregateViews = {
  nonHvacEquipments: NonHvacEquipmentsById[];
  isLoadingNonHvacEquipments: boolean;
};

export const initialState = {
  nonHvacEquipments: [
    {
      siteId: '',
      equipment: [],
      networkGroupIds: [],
      cbToEquipmentIds: [
        {
          controlBoardId: 0,
          equipmentIds: [],
        },
      ],
    },
  ],
  isLoadingNonHvacEquipments: false,
};

const aggregateViews = (state: AggregateViews = initialState, action: any) => {
  switch (action.type) {
    case Actions.GET_NONHVAC_EQUIPMENTS_LOADING:
      return R.assoc('isLoadingNonHvacEquipments', true, state);
    case Actions.GET_NONHVAC_EQUIPMENTS_SUCCESS:
      return R.pipe(
        R.assoc('isLoadingNonHvacEquipments', false),
        R.assoc('nonHvacEquipments', action.data)
      )(state);
    case Actions.GET_NONHVAC_EQUIPMENTS_ERROR:
      return R.assoc('isLoadingNonHvacEquipments', false, state);
    default:
      return state;
  }
};

export default aggregateViews;
