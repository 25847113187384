export enum Actions {
  CONNECTED = '@@streamApi/CONNECTED',
  ERROR = '@@streamApi/ERROR',
  CLOSED = '@@streamApi/CLOSED',
  SEND_MESSAGE = '@@streamApi/SEND_MESSAGE',
  RECEIVED_DEVICE_STATUS = '@@streamApi/RECEIVED_DEVICE_STATUS',
  RECEIVED_DEVICE_READING = '@@streamApi/RECEIVED_DEVICE_READING',
  RECEIVED_ENERGY_PRO_READING = '@@streamApi/RECEIVED_ENERGY_PRO_READING',
  RECEIVED_PROPOSED_READING = '@@streamApi/RECEIVED_PROPOSED_READING',
  RECEIVED_FILE_INFO = '@@streamApi/RECEIVED_FILE_INFO',
  RECEIVED_SUBSCRIBED_REPORT = '@@streamApi/RECEIVED_SUBSCRIBED_REPORT',
  RECEIVED_REPORT_STATUS = '@@streamApi/RECEIVED_REPORT_STATUS',
  RECEIVED_CONTROL_BOARD_ILO_STATE = '@@streamApi/RECEIVED_CONTROL_BOARD_ILO_STATE',
  RECEIVED_DEVICE_INSTALL_LIST = '@@streamApi/RECEIVED_DEVICE_INSTALL_LIST',
}

export enum CommandType {
  SUBSCRIBE = 'SUBSCRIBE',
  UNSUBSCRIBE = 'UNSUBSCRIBE',
  ENQUEUE_GATEWAY_ACTION = 'ENQUEUE_GATEWAY_ACTION',
  ENQUEUE_CONFIG_UPDATE = 'ENQUEUE_CONFIG_UPDATE',
  ENQUEUE_SENSOR_ACTION = 'ENQUEUE_SENSOR_ACTION',
  ENQUEUE_FIRMWARE_UPDATE = 'ENQUEUE_FIRMWARE_UPDATE',
  ENQUEUE_ILO_CONFIG_UPDATE = 'ENQUEUE_ILO_CONFIG_UPDATE',
}

export enum Channel {
  DEVICE_STATUS = 'DEVICE_STATUS',
  SENSOR_READINGS = 'SENSOR_READINGS',
  ENERGY_SENSOR_READING = 'ENERGY_SENSOR_READING',
  PING = 'PING',
  FILE_INFO = 'FILE_INFO',
  PROPOSED_READING = 'PROPOSED_READING',
  REPORT_WORKER = 'REPORT_WORKER',
  REPORTS_STATUS = 'REPORTS_STATUS',
  CONTROL_BOARD_ILO_STATE = 'CONTROL_BOARD_ILO_STATE',
  DEVICE_INSTALL_LIST = 'DEVICE_INSTALL_LIST',
}

export enum ConfigAction {
  NETWORK_INTERFACE = 'NETWORK_INTERFACE',
  LOG_CONFIG = 'LOG_CONFIG',
  SIREN_CONFIG = 'SIREN_CONFIG',
  // RF_CONFIG = 'RF_CONFIG'
}

export enum GatewayAction {
  REBOOT = 'REBOOT',
  CLEAR_BUFFER = 'CLEAR_BUFFER',
  EMIT_DEVICE_INFO = 'EMIT_DEVICE_INFO',
  EMIT_DEVICE_STATUS = 'EMIT_DEVICE_STATUS',
  EMIT_DEVICE_CONFIG = 'EMIT_DEVICE_CONFIG',
  EMIT_PAIRED_SENSOR_LIST = 'EMIT_PAIRED_SENSOR_LIST',
  EMIT_SCANNED_SENSOR_LIST = 'EMIT_SCANNED_SENSOR_LIST',
  EMIT_SENSORS_WHITELIST = 'EMIT_SENSORS_WHITELIST',
  EMIT_PRO2_CONFIG = 'EMIT_PRO2_CONFIG',
  CLEAR_PRO2_CONFIG = 'CLEAR_PRO2_CONFIG',
  TOGGLE_PAIRING_MODE = 'TOGGLE_PAIRING_MODE',
  TOGGLE_BUZZER = 'TOGGLE_BUZZER',
  TOGGLE_LED = 'TOGGLE_LED',
  ADD_SENSOR_TO_WHITELIST = 'ADD_SENSOR_TO_WHITELIST',
  REMOVE_SENSOR_FROM_WHITELIST = 'REMOVE_SENSOR_FROM_WHITELIST',
  SIREN_ON = 'SIREN_ON',
  SIREN_OFF = 'SIREN_OFF',
  SIREN_ACTIVATE = 'SIREN_ACTIVATE',
  SIREN_DEACTIVATE = 'SIREN_DEACTIVATE',
  SIREN_MUTE = 'SIREN_MUTE',
}

export enum SensorAction {
  SET_REPORT_INTERVAL = 'SET_REPORT_INTERVAL',
  SET_POLLING_INTERVAL = 'SET_POLLING_INTERVAL',
  EMIT_SENSOR_CONFIG = 'EMIT_SENSOR_CONFIG',
  EMIT_SENSOR_STATUS = 'EMIT_SENSOR_STATUS',
  TOGGLE_LED = 'TOGGLE_LED',
}

export const subscribeToDeviceStatus = (
  vendor: string,
  uuid: string,
  id?: number | string
) => ({
  type: Actions.SEND_MESSAGE,
  data: {
    type: CommandType.SUBSCRIBE,
    channel: Channel.DEVICE_STATUS,
    vendor,
    uuid,
    id,
  },
});

export const unsubscribeFromDeviceStatus = (
  vendor: string,
  uuid: string,
  id?: number | string
) => ({
  type: Actions.SEND_MESSAGE,
  data: {
    type: CommandType.UNSUBSCRIBE,
    channel: Channel.DEVICE_STATUS,
    vendor,
    uuid,
    id,
  },
});

export const subscribeToDeviceReadings = (
  vendor: string,
  uuid: string,
  id?: number | string
) => {
  return {
    type: Actions.SEND_MESSAGE,
    data: {
      type: CommandType.SUBSCRIBE,
      channel: Channel.SENSOR_READINGS,
      vendor,
      uuid,
      id,
    },
  };
};

export const unsubscribeFromDeviceReadings = (
  vendor: string,
  uuid: string,
  id?: number | string
) => ({
  type: Actions.SEND_MESSAGE,
  data: {
    type: CommandType.UNSUBSCRIBE,
    channel: Channel.SENSOR_READINGS,
    vendor,
    uuid,
    id,
  },
});

export const subscribeToEnergyProSensorReadings = (
  vendor: string,
  uuid: string,
  id: number
) => {
  return {
    type: Actions.SEND_MESSAGE,
    data: {
      type: CommandType.SUBSCRIBE,
      channel: Channel.ENERGY_SENSOR_READING,
      vendor,
      uuid,
      id,
    },
  };
};

export const subscribeToProposedPhaseReadings = (
  vendor: string,
  uuid: string,
  id: number
) => {
  return {
    type: Actions.SEND_MESSAGE,
    data: {
      type: CommandType.SUBSCRIBE,
      channel: 'PROPOSED_READING',
      vendor,
      uuid,
      id,
    },
  };
};

export const unsubscribeToProposedPhaseReadings = (
  vendor: string,
  uuid: string,
  id: number
) => {
  return {
    type: Actions.SEND_MESSAGE,
    data: {
      type: CommandType.UNSUBSCRIBE,
      channel: 'PROPOSED_READING',
      vendor,
      uuid,
      id,
    },
  };
};

export const unsubscribeFromEnergyProSensorReadings = (
  vendor: string,
  uuid: string,
  id: number
) => ({
  type: Actions.SEND_MESSAGE,
  data: {
    type: CommandType.UNSUBSCRIBE,
    channel: Channel.ENERGY_SENSOR_READING,
    vendor,
    uuid,
    id,
  },
});

export const enqueueGatewayAction = (
  action: GatewayAction,
  id: string,
  extraParams: any = {}
) => ({
  type: Actions.SEND_MESSAGE,
  data: {
    type: CommandType.ENQUEUE_GATEWAY_ACTION,
    id,
    action,
    ...extraParams,
  },
});

export const enqueueConfigUpdate = (
  action: ConfigAction,
  id: string,
  extraParams: any = {}
) => ({
  type: Actions.SEND_MESSAGE,
  data: {
    type: CommandType.ENQUEUE_CONFIG_UPDATE,
    id,
    action,
    ...extraParams,
  },
});

export const enqueueFirmwareUpdateAction = (
  vendor: string,
  id: string,
  version: string,
  filePath: string,
  useTLS: boolean
) => ({
  type: Actions.SEND_MESSAGE,
  data: {
    vendor,
    type: CommandType.ENQUEUE_FIRMWARE_UPDATE,
    id,
    version,
    filePath,
    useTLS,
  },
});

export const enqueueSensorAction = (
  action: SensorAction,
  id: string,
  extraParams: any = {}
) => ({
  type: Actions.SEND_MESSAGE,
  data: {
    type: CommandType.ENQUEUE_SENSOR_ACTION,
    id,
    action,
    ...extraParams,
  },
});

export const enqueueSiteControllerIloOverrideAction = (
  id: string,
  iLO_state?: boolean,
  iLO_timeout?: number
) => ({
  type: Actions.SEND_MESSAGE,
  data: {
    type: CommandType.ENQUEUE_ILO_CONFIG_UPDATE,
    id,
    iLO_state,
    iLO_timeout,
  },
});

export const subscribeToUploadProgress = (uuid: string) => ({
  type: Actions.SEND_MESSAGE,
  data: {
    type: CommandType.SUBSCRIBE,
    channel: Channel.FILE_INFO,
    uuid,
  },
});

export const unsubscribeFromUploadProgress = (uuid: string) => ({
  type: Actions.SEND_MESSAGE,
  data: {
    type: CommandType.UNSUBSCRIBE,
    channel: Channel.FILE_INFO,
    uuid,
  },
});

// Subscribe and unscribe for report worker
export const subscribeToReportWorker = ({
  jobId,
}: {
  jobId?: number | string;
}) => ({
  type: Actions.SEND_MESSAGE,
  data: {
    type: CommandType.SUBSCRIBE,
    channel: Channel.REPORT_WORKER,
    jobId,
  },
});

export const unsubscribeFromReportWorker = ({
  jobId,
}: {
  jobId?: number | string;
}) => ({
  type: Actions.SEND_MESSAGE,
  data: {
    type: CommandType.UNSUBSCRIBE,
    channel: Channel.REPORT_WORKER,
    jobId,
  },
});

// Subscribe to report worker status
export const subscribeToWorkerStatus = (jobId?: string | number) => {
  return {
    type: Actions.SEND_MESSAGE,
    data: {
      type: CommandType.SUBSCRIBE,
      channel: Channel.REPORTS_STATUS,
      jobId,
    },
  };
};

// Un-Subscribe to report worker status
export const unsubscribeFromWorkerStatus = (jobId?: string | number) => {
  return {
    type: Actions.SEND_MESSAGE,
    data: {
      type: CommandType.UNSUBSCRIBE,
      channel: Channel.REPORTS_STATUS,
      jobId,
    },
  };
};

export const subscribeToControlBoardIloState = (uuid: string) => ({
  type: Actions.SEND_MESSAGE,
  data: {
    type: CommandType.SUBSCRIBE,
    channel: Channel.CONTROL_BOARD_ILO_STATE,
    uuid,
  },
});

export const unsubscribeFromControlBoardIloState = (uuid: string) => ({
  type: Actions.SEND_MESSAGE,
  data: {
    type: CommandType.UNSUBSCRIBE,
    channel: Channel.CONTROL_BOARD_ILO_STATE,
    uuid,
  },
});

export const subscribeToDeviceList = (uuid: string) => {
  return {
    type: Actions.SEND_MESSAGE,
    data: {
      type: CommandType.SUBSCRIBE,
      channel: Channel.DEVICE_INSTALL_LIST,
      uuid,
    },
  };
};

export const unsubscribeFromDeviceList = (uuid: string) => ({
  type: Actions.SEND_MESSAGE,
  data: {
    type: CommandType.UNSUBSCRIBE,
    channel: Channel.DEVICE_INSTALL_LIST,
    uuid,
  },
});
